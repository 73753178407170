import { Controller } from "react-hook-form";
import MaskedInput from 'react-text-mask';
import TextField from '@material-ui/core/TextField';
import isPHN from "./isPHN";
import { PatientInfo } from "../../../shared/types";
import { ControlledInputProps } from "@alethea-medical/react-components/dist/react-hook-form/shared/types";


interface PHNInputProps extends ControlledInputProps {
    required?: boolean,
    setValue?: (patientInfo: PatientInfo) => void,
    skipServerWarmup?: boolean,
    disabled?: boolean
    province?: string
    rules: any
}


interface TextMaskCustomProps {
    inputRef: (ref: HTMLInputElement | null) => void;
}
  
const TextMaskAB = (props: TextMaskCustomProps) => {
    const {inputRef,...other} = props
    return (
        <MaskedInput
           {...other}
            ref={(ref: any) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
            showMask
            guide={false}
        />
    );
}

const TextMaskBC = (props: TextMaskCustomProps) => {
    const {inputRef,...other} = props
    return (
        <MaskedInput
           {...other}
            ref={(ref: any) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            mask={[/\d/, /\d/, /\d/, /\d/,' ', /\d/, /\d/, /\d/ ,' ',/\d/ ,/\d/, /\d/]}
            showMask
            guide={false}
        />
    );
}


//Dropdown lookup for patient info based on PHN
//Value returned is a PatientInfo object
const PHNInput = ({  name, control, defaultValue, rules, label, disabled, province, ...rest  }: PHNInputProps) => {

    
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            rules={Object.assign({ validate: {
                isPHN: (phn: string) => {
                    return isPHN(phn, province);
                }
            }}, {...rules})}
            render={({field, fieldState}) => {
        
                const inputProps: any = {}
                    
                switch(province) {
                    case "BC":
                        inputProps.inputComponent = TextMaskBC;
                        break;
                    case "AB":
                        inputProps.inputComponent = TextMaskAB;                                    
                        break;
                }
                return (
                    <TextField   
                        {...field}
                        {...rest}
                        {...{InputProps: inputProps}}
                        label={label}
                        error={fieldState.error !== undefined}
                        helperText={fieldState.error?.message}
                        onChange={(e) => {
                            const phn = e.target.value.split('-').join('').split(' ').join('');
                            field.onChange(phn);
                        }}
                        margin="dense"
                        fullWidth
                        variant="outlined"
                        disabled={disabled}
                        InputLabelProps={{
                            disableAnimation: true
                        }}
                    />
                )
            }}
        />
    );
}

export default PHNInput;