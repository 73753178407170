import analytics from "./analytics";

function logAnalyticsEvent (eventName: string, eventObj?: any) {
	if(process.env.NODE_ENV === "production")
	{
		if(eventObj)
			analytics.logEvent(eventName, eventObj);
		else
            analytics.logEvent(eventName);
	}
	else
	{
		if(eventObj)
			console.log(`environment not in production, not logging analytics event: ${eventName} => ${eventObj}`);
		else
		    console.log(`environment not in production, not logging analytics event: ${eventName}`);
	}

}

export default logAnalyticsEvent;