import React, { useCallback, useState } from 'react';
import logAnalyticsEvent from '../../firebase/logAnalyticsEvent';
import ReactPhotoGallery from 'react-photo-gallery';
import { FileProps } from "../../types";
import GalleryRenderer from "./GalleryRenderer";
import ReactPlayer from "react-player";
import { PaperModal } from '@alethea-medical/alethea-components';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        modalContainer: {
            height: "100%",
            width: "100%",
        },
        mediaContainer: {
            maxWidth: "100%",
            maxHeight: "100%",
            padding: theme.spacing(2)
        },
        modalImage: {
            maxWidth: "100%",
            maxHeight: "100%",
            display: "block",
            margin: "auto",
        },
        modalVideo: {
            maxWidth: "100%",
            maxHeight: "100%",

        }
    }),
);

interface GalleryProps {
    files: FileProps[]
}

const Gallery = ({ files }: GalleryProps) => {

    const classes = useStyles();

    const [showModal, setShowModal] = useState(false);
    const [modalFile, setModalFile] = useState<FileProps>();

    const handleShowModal = (file: FileProps) => {
        logAnalyticsEvent("message_attachment_view");
        setModalFile(file);
        setShowModal(true);
    }

    const imageRenderer = useCallback(
        ({ index, key, photo }) => (
          <GalleryRenderer
            key={`${key}_${index}`}
            file={photo}
            handleShowModal={handleShowModal}
          />
        ),
        []
    );


    return (
        <>
            <ReactPhotoGallery photos={files.map((file) => {
                return {
                    ...file,
                    height: 1,
                    width: 1,
                }
            })} renderImage={imageRenderer}/>
            {/* Videos cause flex width and height to make the modal overflow offscreen */}
            <PaperModal show={showModal} setShow={setShowModal} enablePadding flexHeight={modalFile?.fileType === "image"} flexWidth={modalFile?.fileType === "image"}>
                    {modalFile !== undefined && 
                        <>
                            <Grid container className={classes.modalContainer} justifyContent="flex-start" direction='column' alignItems="center">
                                <Grid item>
                                    <Typography>
                                        {modalFile.filename}
                                    </Typography>
                                </Grid>
                                <Grid item className={classes.mediaContainer}>
                                    {modalFile.fileType === "image" && 
                                        <img className={classes.modalImage} src={modalFile.src}/>
                                    }
                                    {modalFile.fileType === "video" && 
                                        <ReactPlayer 
                                            className={classes.modalVideo}
                                            url={modalFile.src}
                                            controls
                                            playing={showModal}
                                        />
                                    }
                                </Grid>
                            </Grid>
                        </>
                    }    


            </PaperModal>
        </>
    );
}

export default Gallery;