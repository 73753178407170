import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import analytics from "../firebase/analytics";

const logCurrentPage = () => {
    analytics.setCurrentScreen(window.location.pathname)
    analytics.logEvent("screen_view");
};
  
const PageAnalytics = () => {
    const history = useHistory();

    useEffect(() => {
        if(history !== undefined) {
            logCurrentPage(); // log the first page visit
            history.listen(() => {
                logCurrentPage();
            });
        }
    }, [history]);

    return (
        <>
        </>
    );
};

export default PageAnalytics;