import { useEffect, useRef, useState } from 'react';


function useSizeManager () {

	const sizeRef = useRef<HTMLDivElement | null>(null);

	const [height, setHeight] = useState(0);
    const [width, setWidth] = useState(0);

    //Set height states based on element height
	const updateSize = () => {
        setTimeout(() => {
            if(sizeRef?.current) {
                if(setHeight)
                    setHeight(sizeRef.current.clientHeight);	
                if(setWidth)
                    setWidth(sizeRef.current.clientWidth)
            }
        }, 1)	
	}

	//Update size when window size changes
	useEffect(() => {		
		window.addEventListener('resize', updateSize);
		updateSize();
		return () => window.removeEventListener('resize', updateSize);
	}, []);


    return {
        sizeRef, updateSize, height, width
    }


}

export default useSizeManager;