import React  from "react";
import { FileProps } from "../../types";
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        media: {
            cursor: "pointer",
            overflow: "hidden",
            position: "relative",
            margin: theme.spacing(0.5),
            "&:hover": {
                outline: "4px solid",
                outlineColor: theme.palette.primary.light
            }
        },
        videoContainer: {
            position: 'relative'
        },
        playIcon: {
            position: 'absolute',
            top: '20px',
            left: '10px',
            zIndex: 10
        },
        playIconBG: {
            fill: "white",
            position: "absolute"
        }
    }),
);


interface GalleryFileProps extends FileProps {
    width: number,
    height: number
}

interface GalleryRenderComponentProps {
    file: GalleryFileProps,
    handleShowModal: (file: FileProps) => void,
}

const GalleryRenderer = ( { file, handleShowModal }: GalleryRenderComponentProps) => {   
    const classes = useStyles();

    const handleClick = (e: any) => {
        handleShowModal(file);
    };

    return (
        <>
            {file.fileType === "image" && 
                <img className={classes.media} src={file.src} onClick={handleClick} height="25%" width="25%"/>
            }
            {file.fileType === "video" && 
                <div className={classes.videoContainer} onClick={handleClick}>
                    <svg className={classes.playIcon} width="20px" height="20px">
                        <circle className={classes.playIconBG} cx="10" cy="10" r="10" />
                        <PlayCircleFilledIcon  color="primary"/>
                    </svg>
                    <video 
                        className={classes.media}
                        src={file.src}
                        width="25%"
                        preload="metadata"
                    />
                </div>                
            }
        </>

    );
};

export default GalleryRenderer;

