import { useState } from "react";
import usePrevious from "../../components/usePrevious";
import { MessageFolder } from "../../../shared/types";
import { MessageDict } from "./Messages";


function useUndoMoveFolder (state: MessageDict, setState: (prevState: MessageDict) => void, undoCallback: (prevFolder: MessageFolder, changedIds: string[]) => void) {

    const prevState = usePrevious<MessageDict>(state);
    const [undoIds, setUndoIds] = useState<string[]>([]);
    const [prevFolder, setPrevFolder] = useState<MessageFolder>();

    const undo = () => {
        if(prevFolder !== undefined && prevState !== undefined) {
            setState(prevState);
            undoCallback(prevFolder, undoIds);
        }

    }

    return { undo, setUndoIds, setPrevFolder };
}

export default useUndoMoveFolder;