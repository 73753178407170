import { useState, useContext } from 'react';
import clsx from "clsx";
import logAnalyticsEvent from '../../../firebase/logAnalyticsEvent';
import ColumnViewContainer from '../../../components/ColumnViewContainer';
import { Control, UseFormTrigger, useForm, useWatch } from "react-hook-form";
import { useHistory } from "react-router-dom";
import functions from "../../../firebase/functions";
import auth from '../../../firebase/auth';
import { ProcessState, ProcessStatus, useProcessState } from "@alethea-medical/alethea-components";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import * as QueryString from "query-string"
import { withRouter } from 'react-router-dom';
import { createProfilePage, defaultPage, supportEmail } from "../../../strings";
import FormNewPassword from '../../../components/FormNewPassword';
import { useEffect } from 'react';
import { AuthContext } from '../../../config/AuthProvider';
import PHNInput from '../../../components/PHNInput/PHNInput';
import FormSelect from "../../../components/FormInputFields/FormSelect";
import Alert from "@material-ui/lab/Alert"


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        form: {
            width: "100%"
        },
        header: {
            marginBottom: theme.spacing(1)
        },
        submit: {
            marginTop: theme.spacing(2),
        },
        status: {
            marginTop: theme.spacing(2),
            width: "100%"
        },
        alignImgCenter: {
            marginRight: "auto",
            marginLeft: "auto",
            display: "block"
        },
        alignCenter: {
            textAlign: "center"
        }
    }),
);

const defaultValues = {
    phn: "",
    patientProvince: "AB",
    password: "",
    confirmPassword: ""
}

const CompleteSignup = withRouter(({ location }) => {
    const classes = useStyles();
    const { handleSubmit, control, trigger, formState: { isDirty } } = useForm({ mode: "onTouched", defaultValues });
    const history = useHistory();
    const authContext = useContext(AuthContext);
    const updatePasswordWithToken = functions.httpsCallable("profile-updatePasswordWithToken");
    const patientProvince = useWatch({ control, name: "patientProvince" });

    const { processState, setProcessState, processErrorMessage, errorHandler } = useProcessState({ logAnalyticsEvent });

    const [alreadySignedUp, setAlreadySignedUp] = useState(false);

    useEffect(() => {
        if (authContext.authenticated) {
            if (authContext?.profile?.signedUp)
                history.push(defaultPage);
            else
                history.push(createProfilePage);
        }
    }, [authContext.authenticated])

    useEffect(()=> {
        // Only show error if form is not first load
        if(isDirty)
            trigger("phn")
    }, [patientProvince])


    const onSubmit = (data: any) => {
        const params = QueryString.parse(location.search);
        if (params.code !== undefined) {
            logAnalyticsEvent("complete_signup_start");

            setProcessState(ProcessState.running);

            updatePasswordWithToken({ newPassword: data.password, token: params.code, phn: data.phn })
            .then((result) => {
                if (result.data.alreadySignedUp) {
                    setAlreadySignedUp(true);
                    setProcessState(ProcessState.idle);
                }
                else {
                    return auth.signInWithCustomToken(result.data.signinToken)
                        .then(() => {
                            logAnalyticsEvent("complete_signup_success");

                            history.push(createProfilePage);
                        })
                }
            })
            .catch((error: Error) => {
                errorHandler({
                    error: error,
                    userMessage: "An error occurred during the signup process",
                    analyticsLog: "complete_signup_failed"
                })
            })
        }
        else {
            errorHandler({
                userMessage: "Your signup link is invalid",
                analyticsLog: "complete_signup_invalid_link"
            })
        }
    }

    const isDisabled = () => {
        return processState === ProcessState.running || processState === ProcessState.success;
    }


    return (
        <ColumnViewContainer>
            <img src="/logo_big.png" alt="Alethea Logo" width="15%" className={classes.alignImgCenter} />
            <Typography component="h1" variant="h5" className={clsx(classes.header, classes.alignCenter)}>
                Alethea Care
            </Typography>
            {alreadySignedUp === false && (
                <>
                    <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                        <Typography variant="subtitle1" className={classes.alignCenter}>
                            To complete the sign up process, verify your Patient Health Number
                        </Typography>

                        <Grid item xs={12}>
                            <FormSelect control={control as Control<any>} name="patientProvince" label="Province" disabled={isDisabled()}
                                options={["AB", "BC", "MB", "NB", "NL", "NT", "NS", "NU", "ON", "PE", "QC", "SK", "YT"]}
                                rules={{ required: { value: true, message: "Province is required." } }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <PHNInput
                                control={control as Control<any>}
                                name="phn"
                                label="Patient Health Number"
                                rules={{required: {value: true, message: "PHN is required"}}}
                                disabled={isDisabled()}
                                province={patientProvince}
                            />
                        </Grid>
               

                        <Typography variant="subtitle1" className={classes.alignCenter}>
                            and create a new password.
                        </Typography>

                        <FormNewPassword control={control as Control<any>} trigger={trigger as UseFormTrigger<any>} />
                        <Grid item xs={12}>
                            <Alert severity="info">If you are receiving family member's messages on this account, you may need to enter their Patient Health Number instead.</Alert>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                className={classes.submit}
                                variant="contained"
                                color="primary"
                                type="submit"
                                fullWidth
                                disabled={isDisabled()}
                            >
                                Sign Up
                            </Button>
                        </Grid>
                    </form>
                </>
            )}
            {(processState !== ProcessState.error && alreadySignedUp === true) && (
                <Typography variant="subtitle1" className={classes.alignCenter}>
                    User is already signed up. <a href={"/auth/login"}>Click here to login</a>
                </Typography>
            )}
            <Grid item xs={12} className={classes.status}>
                <ProcessStatus state={processState} errorMessage={processErrorMessage} successMessage={""} />
            </Grid>
            {processState == ProcessState.error && (
                <Grid item xs={12} className={classes.status}>
                    <Typography variant="subtitle1" className={classes.alignCenter}>
                        If this error persists please contact us at <a href={`mailto:${supportEmail}`}>{supportEmail}</a> for assistance
                    </Typography>
                </Grid>
            )}
        </ColumnViewContainer>
    );
})

export default CompleteSignup;