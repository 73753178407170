import { useEffect, useState } from 'react';
import { Control, useForm, useWatch } from "react-hook-form";
import functions from "../../../firebase/functions";
import ColumnViewContainer from '../../../components/ColumnViewContainer';
import { EmailInput } from "@alethea-medical/react-components"; 
import { ProcessState, ProcessStatus, useProcessState } from '@alethea-medical/alethea-components';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import logAnalyticsEvent from '../../../firebase/logAnalyticsEvent';
import PHNInput from "../../../components/PHNInput/PHNInput";
import FormSelect from "../../../components/FormInputFields/FormSelect";
import { supportEmail } from "../../../strings";
import Alert from "@material-ui/lab/Alert"

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        form: {
            width: "100%"
        },
        header:{
            textAlign: 'center',
            marginBottom: theme.spacing(1)
        },
        submit: {
            marginTop: theme.spacing(2)
        },
        typography: {
            textAlign: "center"
        },
        alignCenter: {
            textAlign: "center"
        }
    }),
);

const defaultValues = {
    email: "",
    phn: "",
    patientProvince: "AB"
}


const ForgotPassword = () => {
    const classes = useStyles();
    const { handleSubmit, control, reset, trigger, formState: { isDirty }} = useForm({mode: "onTouched", defaultValues});

    const [successEmail, setSuccessEmail] = useState("")
    const sendForgotPasswordEmail = functions.httpsCallable("profile-sendForgotPasswordEmail");
    const patientProvince = useWatch({ control, name: "patientProvince" });

    const { processState, setProcessState, processErrorMessage, errorHandler } = useProcessState({ logAnalyticsEvent });

    useEffect(()=> {
        // Only show error if form is not first load
        if(isDirty)
            trigger("phn")  
    }, [patientProvince])

    const onSubmit = (data: any) => {
        logAnalyticsEvent("forgot_password_start");
        setProcessState(ProcessState.running);
        
        sendForgotPasswordEmail(data)
            .then(() => {
                logAnalyticsEvent("forgot_password_success");
                setProcessState(ProcessState.success);
                setSuccessEmail(data.email)
                reset(defaultValues);
            })
            .catch((error: Error) => {
                errorHandler({
                    error: error,
                    userMessage: "An error occurred while sending your password reset link",
                    analyticsLog: "forgot_password_failed"
                })
            })
    }
    
    const isDisabled = () => {
        return processState === ProcessState.running || processState  === ProcessState.success;
    }

    return (
        <ColumnViewContainer>
            <Typography component="h1" variant="h5" className={classes.header}>
                Recover Password
            </Typography>
            <Typography variant="subtitle1" className={classes.typography}>
                Please enter the email address for your account. A link to reset your password will be sent to you.
            </Typography>
            <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <EmailInput
                            control={control as Control<any>}
                            name="email"
                            label="Email"
                            rules={{required: {value: true, message: "Email is required"}}}
                            disabled={isDisabled()}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" className={classes.typography}>
                            In order to receive a password reset link, you must validate your Patient Health Number for security reasons.
                        </Typography>
                    </Grid>
            
                    <Grid item xs={12}>
                        <FormSelect control={control as Control<any>} name="patientProvince" label="Province" disabled={isDisabled()}
                            options={["AB", "BC", "MB", "NB", "NL", "NT", "NS", "NU", "ON", "PE", "QC", "SK", "YT"]}
                            rules={{ required: { value: true, message: "Province is required." } }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <PHNInput
                            control={control as Control<any>}
                            name="phn"
                            label="Patient Health Number"
                            rules={{required: {value: true, message: "PHN is required"}}}
                            disabled={isDisabled()}
                            province={patientProvince}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Alert severity="info">If you are receiving family member's messages on this account, you may need to enter their Patient Health Number instead.</Alert>
                    </Grid>
                    <Grid item xs={12}>
                        <Button 
                            className={classes.submit}
                            variant="contained" 
                            color="primary" 
                            type="submit" 
                            fullWidth
                            disabled={isDisabled()}
                        >
                            Submit
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <ProcessStatus state={processState} errorMessage={processErrorMessage} successMessage={`If a matching account was found an email was sent to ${successEmail} to allow you to reset your password.`}/>
                    </Grid>
                    {processState === ProcessState.success && 
                        <Grid item>
                            <Button variant="contained" color="primary" onClick={() => setProcessState(ProcessState.idle)}>OK</Button>
                        </Grid>
                    }
                    {processState === ProcessState.error && (
                        <Grid item xs={12}>
                            <Typography variant="subtitle1" className={classes.alignCenter}>
                                If this error persists please contact us at <a href={`mailto:${supportEmail}`}>{supportEmail}</a> for assistance
                            </Typography>
                        </Grid>
                    )}
                </Grid>
            </form>
        </ColumnViewContainer>
    );
}

export default ForgotPassword;