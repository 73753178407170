import { AppRoute } from "@alethea-medical/alethea-components"



interface AppRoutes {
    [path: string]: AppRoute
}

const routes: AppRoutes = {
    messages: {
        path: "/dashboard/messages",
        name: "Messages"
    },
    profile: {
        path: "/dashboard/profile",
        name: "Profile"
    },
    auth_login: {
        path: "/auth/login",
        name: "Login"
    },
    auth_logout: {
        path: "/auth/logout",
        name: "Logout"
    },
    auth_forgotPassword: {
        path: "/auth/forgot-password",
        name: "Forgot Password"
    },
    auth_resetPassword: {
        path: "/auth/reset-password",
        name: "Reset Password"
    },
    auth_completeSignup: {
        path: "/auth/complete-signup",
        name: "Complete Signup"
    },
    signup_createProfile: {
        path: "/signup/create-profile",
        name: "Create Profile"
    },

}

export default routes;