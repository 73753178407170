import React, { useContext } from 'react';
import logAnalyticsEvent from '../../firebase/logAnalyticsEvent';
import { AuthContext } from '../../config/AuthProvider';
import { useForm } from "react-hook-form";
import db from "../../firebase/db";
import { PaperPage, ProcessState, ProcessStatus, useProcessState } from '@alethea-medical/alethea-components';
import EditProfile from '../../components/EditProfile';

import Grid from '@material-ui/core/Grid';
import { FormCheckbox } from '@alethea-medical/react-components';
import Button from '@material-ui/core/Button';
import getProfile from '../../shared/getProfile';


const Profile = () => {
	const { handleSubmit, control, trigger } = useForm({mode: "onTouched"});
    
    const authContext = useContext(AuthContext);

    const { processState, setProcessState, processErrorMessage, errorHandler } = useProcessState({ logAnalyticsEvent });



    const onSubmit = (data: any) => {
        logAnalyticsEvent("profile_update_start");

		setProcessState(ProcessState.running);
        db.collection("users").doc(authContext.uid).update({
            firstName: data.firstName,
            lastName: data.lastName,
            phone: data.phone,
            phn: data.phn,
            dateOfBirth: data.dateOfBirth,
            province: data.province,
            preferences: {
                emailNotifications: data.emailNotifications
            }
        })
        .then(() => {
            if(data.emailNotifications === false && authContext?.profile?.preferences?.emailNotifications === true)
                logAnalyticsEvent("email_notifications_unsubscribe");

            return getProfile(authContext.uid ? authContext.uid : "")
        })
        .then((profile) => {
            logAnalyticsEvent("profile_update_success");

            authContext.setProfile(profile);
            setProcessState(ProcessState.success);
            setTimeout(() => {
                setProcessState(ProcessState.idle);
                
            }, 1000)
        })
        .catch((error: any) => {
            errorHandler({
                error: error,
                userMessage: "Error updating profile",
                hideErrorMessage: true,
                analyticsLog: "profile_update_failed"
            })            
        });
        
	}

	
	const isDisabled = () => {
		return processState === ProcessState.running || processState === ProcessState.success;
	}

    return (
        <>
            <PaperPage enablePadding>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <Grid container justifyContent="center" spacing={2}>
                        <EditProfile control={control} isDisabled={isDisabled()} trigger={trigger} />
                        <Grid item xs={12}>
                            <FormCheckbox
                                control={control}
                                name="emailNotifications"
                                label="Receive email notifications for new messages from your doctor"
                                defaultValue={authContext.profile?.preferences?.emailNotifications}
                                disabled={isDisabled()}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Button 
                                variant="contained" 
                                color="primary" 
                                type="submit" 
                                fullWidth
                                disabled={isDisabled()}
                            >
                                Save
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <ProcessStatus state={processState} errorMessage={processErrorMessage} successMessage={"Profile updated."}/>
                        </Grid>
                    </Grid>
                </form>
            </PaperPage>
        </>
    );
}

export default Profile;