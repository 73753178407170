import React from "react";
import Button from '@material-ui/core/Button';
interface ToggleButtonProps {
    className?: string,
    startIcon?: React.ReactNode,
    toggleState: boolean,
    onClick: React.MouseEventHandler<HTMLButtonElement> | undefined
}

const ToggleButton: React.FC<ToggleButtonProps> = ({ className, startIcon, toggleState, onClick, children }) => {
    return (
        <Button 
            className={className}
            disableElevation
            color="primary"
            variant={toggleState ? "contained" : "outlined"}
            startIcon={startIcon}
            onClick={onClick}
        >
            {children}
        </Button>
    );
}

export default ToggleButton