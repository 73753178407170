import React, { useContext } from 'react';
import { PrivateRoute } from "@alethea-medical/react-components";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import PageAnalytics from "./PageAnalytics";
import { AuthContext } from "./AuthProvider";
import Login from '../views/Auth/Login';
import Logout from '../views/Auth/Logout';
import ForgotPassword from "../views/Auth/ForgotPassword";
import ResetPassword from '../views/Auth/ResetPassword';
import CompleteSignup from "../views/Auth/CompleteSignup";
import CreateProfile from '../views/Auth/CreateProfile';
import routes from './routes';

const AuthRoutes = () => {

    const authContext = useContext(AuthContext);

    return (
        <Router>
            <PageAnalytics/>
            <Switch>
                <PrivateRoute authenticated={authContext.authenticated} path="/dashboard">
                    <AppRoutes/>
                </PrivateRoute>
                <PrivateRoute authenticated={authContext.authenticated} path="/signup">
                    <Switch>
                        <Route path={routes.signup_createProfile.path}>
                            <CreateProfile/>
                        </Route>
                    </Switch>
                </PrivateRoute>
                <Route path={routes.auth_login.path}>
                    <Login/>
                </Route>
                <Route path={routes.auth_logout.path}>
                    <Logout/>
                </Route>
                {/* <Route path="/auth/signup">
                    <Signup/>
                </Route> */}
                <Route path={routes.auth_forgotPassword.path}>
                    <ForgotPassword/>
                </Route>
                <Route path={routes.auth_resetPassword.path}>
                    <ResetPassword/>
                </Route>
                <Route path={routes.auth_completeSignup.path}>
                    <CompleteSignup/>
                </Route>
                <Redirect to={routes.auth_login.path} from= "/"/>
            </Switch>
        </Router>
    );
}

export default AuthRoutes;