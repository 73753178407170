import React, { useState, useEffect } from 'react';


import { MessageFolder } from "../../../shared/types";
import { Message } from "./types";
import storage from "../../firebase/storage";

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import Divider from '@material-ui/core/Divider';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { HeightLayout, HeightLayoutChild, useMobileSizes, useScreenSize, SwipeableOrGrid } from '@alethea-medical/alethea-components';
import PDFViewer from '../../components/PDFViewer';
import useSizeManager from '../../components/useSizeManager';

import AttachmentViewer from './AttachmentViewer';
import Toolbar from "./Toolbar";
import { formatTimeLong } from '../../shared/formatTime';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        subject: {
            paddingTop: theme.spacing(1),
            paddingLeft: theme.spacing(2)
        },
        date: {
            marginTop: theme.spacing(0),
            paddingLeft: theme.spacing(2)
        },
        fullHeight: {
            height: "100%"
        }
    }),
);

interface ViewMessageProps {
    message: Message,
    goBackHandler: () => void,
    markAsUnread: () => void,
    moveFolder: (folder: MessageFolder) => void,
}


const ViewMessage = ({ message, goBackHandler, markAsUnread, moveFolder }: ViewMessageProps) => {

    const classes = useStyles();
    const [pdfURL, setPDFURL] = useState<string | undefined>(undefined);
    const [showAttachments, setShowAttachments] = useState(false);

    useEffect(() => {
        storage.ref(message.messagePDFURI).getDownloadURL()
        .then((url) =>{
            setPDFURL(url);
        })
        .catch((error) => {
            console.error(error);
        })    
    }, [message])

    const isMobileSize = useMobileSizes();
    const { sizeRef: bodySizeRef, height: bodyHeight, width: bodyWidth} = useSizeManager();
    const { heightMinusAppBar } = useScreenSize({});

    return (
        <>
            <HeightLayout height={heightMinusAppBar}>
                <HeightLayoutChild flexDriver>
                    <>
                        <Grid container justifyContent="flex-start" alignItems="center">
                            <Grid item>
                                <IconButton onClick={goBackHandler}>
                                    <ChevronLeftIcon />
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <Toolbar 
                                    folder={message.folder}
                                    atLeastOneSelected={true}
                                    markAsUnread={markAsUnread} 
                                    moveFolder={moveFolder} 
                                    showAttachments={showAttachments}
                                    onShowAttachments={() => { setShowAttachments(!showAttachments) }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Grid className={classes.subject} container justifyContent="flex-start" alignItems="center">
                                    <Typography variant="h5">{message.subject}</Typography>
                                </Grid>
                                <Grid className={classes.date} container justifyContent="flex-start" alignItems="center">
                                    <Typography variant="subtitle1">Sent at {formatTimeLong(message.created.toDate())}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Divider/>
                    </>
                </HeightLayoutChild>
                <HeightLayoutChild flexDriven ref={bodySizeRef}>
                    <SwipeableOrGrid 
                        showSwipe={isMobileSize} 
                        index={showAttachments ? 1 : 0} 
                        onChangeIndex={(index) => {
                            setShowAttachments(index === 1)
                        }}
                        enableStepper={false}
                        showInitialStepper={false}
                        ignoreTouchInput
                        gridSize={[8, 4]} 
                        containerClassName={classes.fullHeight}
                        swipeClassName={classes.fullHeight}
                        gridItemClassName={classes.fullHeight} 
                        gridContainerClassName={classes.fullHeight}>
                        <PDFViewer pdfURL={pdfURL} height={bodyHeight} width={isMobileSize ? bodyWidth : bodyWidth * 8/12}/>
                        <AttachmentViewer messageMediaURIs={message.messageMediaURIs}/>
                    </SwipeableOrGrid>
                </HeightLayoutChild>
            </HeightLayout>
        </>
    );
}


export default ViewMessage;