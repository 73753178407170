import React, { useContext, useEffect, useState } from 'react';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Popper from '@material-ui/core/Popper';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import db from '../../firebase/db';
import logAnalyticsEvent from '../../firebase/logAnalyticsEvent';
import { AuthContext } from '../../config/AuthProvider';


const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            padding: theme.spacing(2),
            paddingBottom: theme.spacing(6),//Make room for button
            backgroundColor: theme.palette.primary.light,
            color: "white",
            position: 'relative'
        },
        closeButton: {
            right: theme.spacing(1),
            bottom: theme.spacing(1),
            position: 'absolute',
            color: "white"
        },
        header: {
            fontSize: "1.5em"
        },
        title: {
            "& a": {//Change color of hyperlinks to make more visible on dark background
                color: "#6bf2e2"
            }
        },
        subtitle: {
            color: theme.palette.grey[700],
        },
        // Popper and arrow taken from https://github.com/mui-org/material-ui/blob/next/packages/material-ui/src/Tooltip/Tooltip.js
        popper: {
            zIndex: 2000,
            '&[x-placement*="bottom"] $arrow': {
                top: 0,
                left: 0,
                marginTop: "-0.71em",
                marginLeft: 4,
                marginRight: 4,
                "&::before": {
                    transformOrigin: "0 100%"
                }
            },
            '&[x-placement*="top"] $arrow': {
                bottom: 0,
                left: 0,
                marginBottom: "-0.71em",
                marginLeft: 4,
                marginRight: 4,
                "&::before": {
                    transformOrigin: "100% 0"
                }
            },
            '&[x-placement*="right"] $arrow': {
                left: 0,
                marginLeft: "-0.71em",
                height: "1em",
                width: "0.71em",
                marginTop: 4,
                marginBottom: 4,
                "&::before": {
                    transformOrigin: "100% 100%"
                }
            },
            '&[x-placement*="left"] $arrow': {
                right: 0,
                marginRight: "-0.71em",
                height: "1em",
                width: "0.71em",
                marginTop: 4,
                marginBottom: 4,
                "&::before": {
                    transformOrigin: "0 0"
                }
            }
          },
          arrow: {
            zIndex: 3000,
            overflow: "hidden",
            position: "absolute",
            width: "1em",
            height: "0.71em" /* = width / sqrt(2) = (length of the hypotenuse) */,
            boxSizing: "border-box",
            color: theme.palette.primary.light,
            "&::before": {
                content: '""',
                margin: "auto",
                display: "block",
                width: "100%",
                height: "100%",
                boxShadow: theme.shadows[1],
                backgroundColor: "currentColor",
                transform: "rotate(45deg)"
            }
        }
    }),
);


interface OneTimeTooltipProps {
    tooltipName: string,
    header?: string,
    title: string | React.ReactChild,
    subtitle?: string[] | string | React.ReactChild | React.ReactChild[],
    placement?: "top" | "bottom" | "right" | "left",
    children: React.ReactElement
}
//Tooltips on
//MOA message
//Patient CC
//Forms

const OneTimeTooltip = ({ tooltipName, header, title, subtitle, placement = "bottom", children }: OneTimeTooltipProps) => {
    const classes = useStyles();
    const authContext = useContext(AuthContext);
    
    const [arrowRef, setArrowRef] = useState<HTMLElement | null>(null);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [showTooltip, setShowTooltip] = useState<boolean>(false);

    const [openTime] = useState<Date>(new Date());


    useEffect(() => {
        if(authContext.uid !== "") {
            db.collection("one_time_tooltips").doc(authContext.uid).get()
            .then((doc) => {
                if(doc.exists) {
                    const data = doc.data();
                    if(data !== undefined) {
                        //Object exists
                        if(data[tooltipName] === true) {
                            setShowTooltip(false);
                        }
                        else {
                            setShowTooltip(true);    
                        }                        
                        return;
                    }
                }

                //Initialize object for user
                return db.collection("one_time_tooltips").doc(authContext.uid).set({})
                .then(() => {
                    setShowTooltip(true);    
                })
            })
            .catch((error: Error) => {
                console.error(error)
            })
        }
    }, [authContext.uid])


    const handleDismiss = () => {
        const updateObj: any = {}
        updateObj[tooltipName] = true;

        db.collection("one_time_tooltips").doc(authContext.uid).update(updateObj).catch((error: Error) => {
            console.error(error)
        })
        const durationOpen = new Date().getTime() - openTime.getTime();
        logAnalyticsEvent(`tooltip_${tooltipName}_dismissed`, {
            durationOpen: durationOpen
        })
        setShowTooltip(false);
    }
    
    const generateSubtitle = () => {
        if(subtitle !== undefined) {
            if(subtitle.constructor === Array) {
                return subtitle.map((s) => {
                    return (
                        <Typography className={classes.subtitle}>{s}</Typography>
                    );
                })
            }
            else {
                return (
                    <Typography className={classes.subtitle}>{subtitle}</Typography>
                );
            }
        }
        else {
            return (
                <></>
            )
        }

    }

    return (
        <>
            <Popper
                className={classes.popper}
                open={showTooltip}
                anchorEl={anchorEl}
                placement={placement}
                modifiers={{
                    arrow: {
                        enabled: true,
                        element: arrowRef
                    }
                }}
            >
                <span className={classes.arrow} ref={setArrowRef}/>
                <Paper className={classes.paper} elevation={4}>
                    {header && <Typography className={classes.header}>{header}</Typography>}
                    <Typography className={classes.title}>{title}</Typography>
                    {generateSubtitle()}
                    <Button className={classes.closeButton} onClick={handleDismiss}>
                        OK
                    </Button>
                </Paper>
            </Popper>
            <div ref={setAnchorEl}>
                {children}
            </div>
        </>
    )
}

export default OneTimeTooltip;