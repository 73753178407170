import React, { useEffect, useContext } from "react";
import auth from "../../../firebase/auth";
import ColumnViewContainer from "../../../components/ColumnViewContainer";
import { useForm } from "react-hook-form";
import { useHistory, withRouter } from "react-router-dom";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';

import { ProcessState, ProcessStatus, useProcessState } from '@alethea-medical/alethea-components';
import { FormTextField, EmailInput } from "@alethea-medical/react-components";
import { defaultPage, createProfilePage } from "../../../strings";
import { AuthContext } from "../../../config/AuthProvider";
import logAnalyticsEvent from "../../../firebase/logAnalyticsEvent";
import { projectEnv } from "../../../firebase/firebase";
import versionStrings from "../../../versionStrings";


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
	root: {
		marginTop: theme.spacing(8),
		flexGrow: 1,
	},
	paper: {
		width: "30%",
		padding: theme.spacing(2),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	form: {
		width: "100%"
	},
	header:{
		textAlign: 'center',
		fontSize: "2.0em",
		marginBottom: theme.spacing(1)
	},
	logo: {
		width: "100%",
		marginBottom: theme.spacing(2)
	},
	loginButton: {
		marginTop: theme.spacing(2)
	},
	signupButton: {
		marginTop: theme.spacing(1),
		width: "100%"

	},
	status: {
		marginTop: theme.spacing(2)
	},
	versionString: {
		marginTop: theme.spacing(2),
		color: theme.palette.primary.main,
		fontSize: "0.75em"
	}
  }),
);


const Login = withRouter((props) => {

	const { handleSubmit, control } = useForm({mode: "onTouched"});

	const classes = useStyles();
	const history = useHistory();
	const authContext = useContext(AuthContext);

	const { processState, setProcessState, processErrorMessage, errorHandler } = useProcessState({ logAnalyticsEvent });

	useEffect(() => {
		//When authenteicated, go to dashboard
		if(authContext.authenticated && authContext.profile !== undefined && authContext.profile.signedUp === true && authContext.profile.eulaSigned === true) {
			redirectToDashboard();
		}
		else if(authContext.authenticated) {
			redirectToCreateProfile();
		}
	}, [authContext.authenticated, authContext.profile]);

	const redirectToDashboard = () => {
		const state = props.location.state as any;
        if(state?.prevPath?.location?.pathname)
        {
            history.push(state.prevPath.location.pathname);
        }
        else{
            history.push(defaultPage);
        }
    }

	const redirectToCreateProfile = () => {
		history.push(createProfilePage);
	}


	const onSubmit = (data: any) => {
		setProcessState(ProcessState.running);
		auth.signInWithEmailAndPassword(data.email, data.password)
		.catch((error: any) => {
			let userMessage: string = ""
			if(error.code === "auth/user-not-found" || error.code === "auth/wrong-password")    
				userMessage = "Invalid login information"
			else
				userMessage = error.message;

			errorHandler({
				userMessage: userMessage,
			});
		});
	}

	const onError = () => {
		errorHandler({
			userMessage: "Invalid login information"
		});
	}
	
	const isDisabled = () => {
		return processState === ProcessState.running || processState === ProcessState.success;
	}

	return (
		<ColumnViewContainer>
			<img className={classes.logo} src="/Alethea Logo.png" alt="Alethea Logo"/>
			<Typography variant="h6" className={classes.header} color="primary">
				Patient Portal
			</Typography>
			<form className={classes.form} onSubmit={handleSubmit(onSubmit, onError)}>
				<Grid item xs={12}>
					<EmailInput
						control={control}
						name="email"
						label="Email"
						rules={{required: {value: true, message: "Email is required"}}}
						disabled={isDisabled()}
					/>
				</Grid>
				<Grid item xs={12}>
					<FormTextField
						control={control}
						name="password"
						label="Password"
						rules={{required: {value: true, message: "Password is required"}}}
						disabled={isDisabled()}
						{...{type: 'password'}}
					/>
				</Grid>
				<Grid item xs={12}>
					<Button 
						className={classes.loginButton}
						variant="contained" 
						color="primary" 
						type="submit" 
						fullWidth
						disabled={isDisabled()}
					>
							Login
					</Button>
				</Grid>
				<Grid item xs={12} className={classes.status}>
					<ProcessStatus state={processState} errorMessage={processErrorMessage} successMessage={""}/>
				</Grid>
			</form>
			<Grid item xs={12}>
				<Typography variant="body2" className={classes.header}>
					<Link
						color="primary"
						component="button"
						variant="body2"
						onClick={() => history.push("/auth/forgot-password")}
						disabled={isDisabled()}
					>
							Forgot password?
					</Link>
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<Typography className={classes.versionString} style={{textAlign: 'center'}}>
					Version {versionStrings.version}{projectEnv !== "prod" ? `, Build ${versionStrings.devBuild}` : ""}
				</Typography>
			</Grid>
			{projectEnv !== "prod" && (
				<Grid item xs={12}>
					<Typography className={classes.versionString} style={{textAlign: 'center'}}>
						Environment: {projectEnv.toUpperCase()}
					</Typography>
				</Grid>
			)}
			{/* <Grid item xs={12} className={classes.signupButton}>
				<Typography variant="body2" className={classes.header}>
					Don't have an account?
				</Typography>
				<Button 
					variant="contained" 
					color="primary" 
					onClick={() => history.push("/auth/signup")} 
					fullWidth
					disabled={isDisabled()}
				>
						Sign Up
				</Button>
			</Grid> */}
			{/* <OrDivider/>

			<ProviderSigninButtons/> */}
		</ColumnViewContainer>
	);
})

export default Login;