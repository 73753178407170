import { useState, useContext } from "react";
import functions from "../../../firebase/functions";
import db from "../../../firebase/db";
import { AuthContext } from "../../../config/AuthProvider";
import ColumnViewContainer from "../../../components/ColumnViewContainer";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import getProfile from "../../../shared/getProfile";
import { defaultPage } from "../../../strings";
import { ProcessState, ProcessStatus } from "@alethea-medical/react-components";
import Grid from '@material-ui/core/Grid';
import { User } from "../../../../shared/types";
import EditProfile from "../../../components/EditProfile";
import Button from '@material-ui/core/Button';
import logAnalyticsEvent from "../../../firebase/logAnalyticsEvent";
import Alert from "@material-ui/lab/Alert"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
	root: {
		marginTop: theme.spacing(8),
		flexGrow: 1,
	},
    form: {
        width: "100%0"
    },
	paper: {
		width: "30%",
		padding: theme.spacing(2),
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
	},
	header:{
		textAlign: 'center',
		marginBottom: theme.spacing(1)
	},
	logo: {
        marginRight: "auto",
        marginLeft: "auto",
        display: "block"
	},
    typography: {
        textAlign: "center"
    },
	status: {
		marginTop: theme.spacing(2)
	},
    submit: {
        marginTop: theme.spacing(2)
    },
  }),
);

const CreateProfile = () => {

	const { handleSubmit, control, watch, trigger } = useForm({mode: "onTouched"});

    const newUserCreateProfile = functions.httpsCallable("profile-newUserCreateProfile");

	const classes = useStyles();
	const history = useHistory();
    const authContext = useContext(AuthContext);
    const eulaSigned = watch("eulaSigned", authContext?.profile?.eulaSigned)

	const [state, setState] = useState<ProcessState>(ProcessState.idle);
	const [errorMessage, setErrorMessage] = useState<string>("");

	const onSubmit = (data: any) => {
        if(authContext.uid && authContext?.user?.email) {
            if ( data.eulaSigned ) { 
             
                logAnalyticsEvent("create_profile_start");
                setState(ProcessState.running);

                const uid = authContext.uid
                let promise;
                if(authContext.profile) {
                    //User profile document already exists
                    promise = db.collection("users").doc(uid).update({
                        firstName: data.firstName,
                        lastName: data.lastName,
                        phone: data.phone,
                        phn: data.phn,
                        dateOfBirth: data.dateOfBirth,
                        signedUp: true,
                        eulaSigned: data.eulaSigned,
                        province: data.province
                    })
                }
                else {
                    //Create new profile document
                    const newUserCreateProfileData: {uid: string, profile: Partial<User>} = {
                        uid: uid,
                        profile: {
                            firstName: data.firstName,
                            lastName: data.lastName,
                            email: authContext.user.email,
                            phone: data.phone,
                            phn: data.phn,
                            dateOfBirth: data.dateOfBirth,
                            eulaSigned: data.eulaSigned,
                            province: data.province
                        }   
                    }
                    promise = newUserCreateProfile(newUserCreateProfileData)
                }
                
                promise
                .then(() => {
                    return getProfile(uid)
                })
                .then((profile) => {
                    logAnalyticsEvent("create_profile_success");
                    authContext.setProfile(profile);
                    history.push(defaultPage);

                })
                .catch((error: any) => {
                    logAnalyticsEvent("create_profile_failed");
                    setErrorMessage(error.message);
                    setState(ProcessState.error);
                });
            } 
        }   
	}
	
	const isDisabled = () => {
		return state === ProcessState.running || state === ProcessState.success;
	}
    
    return (
        <ColumnViewContainer>
            <img src="/logo_big.png" alt="Alethea Logo" width="15%" className={classes.logo}/>
            <Typography component="h1" variant="h5" className={classes.header}>
                Profile setup
            </Typography>
            <Typography variant="subtitle1" className={classes.typography}>
                Fill out the form below to complete your user profile. Some values may have already been provided by your doctor. Please make sure that they are correct.
            </Typography>
            <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
                <Grid container justifyContent="center" spacing={2}>
                    <EditProfile control={control} isDisabled={isDisabled()} trigger={trigger} showEula/>
                    <Grid item xs={12}>
                        <Button 
                            className={classes.submit}
                            variant="contained" 
                            color="primary" 
                            type="submit" 
                            fullWidth
                            disabled={isDisabled()}
                        >
                            Submit
                        </Button>
                    </Grid>
                    { !eulaSigned && <Grid item>
                        <Alert severity="error">In order to continue you must agree to the End User Licence Agreement</Alert>
                    </Grid> }

                    <Grid item xs={12} className={classes.status}>
                        <ProcessStatus state={state} errorMessage={errorMessage} successMessage={""}/>
                    </Grid>
                </Grid>
            </form>
        </ColumnViewContainer>
    );
}

export default CreateProfile;