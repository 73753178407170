import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            marginTop: theme.spacing(8),
            flexGrow: 1,
            padding: theme.spacing(0, 2)
        },
    })
);

interface AuthViewContainerProps {

}

const ColumnViewContainer: React.FunctionComponent<AuthViewContainerProps> = ({ children }) => {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Grid container justifyContent="center" spacing={2}>
                <Grid item xs={12} sm={10} md={8} lg={4}>
                    {children}
                </Grid>
            </Grid>
        </div>

    );
}

export default ColumnViewContainer;