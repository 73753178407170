
import checkBritishColombiaPHN from "./checkBritishColombiaPHN";
import luhnCheck from "./luhnCheck";


function isPHN (phn: string, province?: string): string | undefined {
    //Don't show length requirement if no input detected
    if(phn === "")
        return undefined;

 
    //phn with no whitespace because it was giving a regex error when just left in
    
    switch(province){
        case "BC": 
            const noWSPhn = phn.replace(/\s/g,'')
            const phnPatBC = /^\d{10}$/i
            if(phnPatBC.test(noWSPhn)){
            
                return checkBritishColombiaPHN(noWSPhn) ? undefined : "PHN is invalid";
            }
            else{
            
                return "PHN must be 10 digits";
            }
        case "AB": 
            const phnPatAB = /^\d{9}$/i
            if(phnPatAB.test(phn)) {
                const fifthDigit = phn.charAt(4);//Extract 5th digit
                const ccNum = phn.slice(0, 4) +  phn.slice(5, 9) + "0";//Form string without 5th digit and a 0 appended to the end
                return luhnCheck(ccNum, fifthDigit) ? undefined : "PHN is invalid";//Run Luhn's algorithm to calculate the check digit. If it matches the 5th digit then the PHN is valid
            }
            else
                return "PHN must be 9 digits";
        default: 
            return undefined;
    }    
}

export default isPHN;