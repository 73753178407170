import firebase from "firebase/app";
import 'firebase/auth';
import 'firebase/analytics';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';
import 'firebase/remote-config';
import { firebaseConfigProd, firebaseConfigStaging, firebaseConfigDev } from "./firebase-config"

const prodURL = 'alethea.care';
const prodURLWWW = 'www.alethea.care';
const prodURLWebApp = 'alethea-patient-portal.web.app';
const stagingURL = 'alethea-patient-portal-staging.web.app';
const devURL = 'alethea-patient-portal-dev.web.app';
const localURL = 'localhost';

type EnvType = "local" | "dev" | "staging" | "prod" | "demo"

let projectEnv: EnvType


let config: any;

const hostname = window.location.hostname;
console.log(hostname)
switch(hostname) {
	case prodURL:
		console.log("Using prod config");
		config = firebaseConfigProd;
		projectEnv = "prod"
		break;
	case prodURLWWW: 
		console.log("Using prod config");
		config = firebaseConfigProd;
		projectEnv = "prod"
		break;
	case prodURLWebApp:
		console.log("Using prod config");
		config = firebaseConfigProd;
		projectEnv = "prod"
		break;
	case stagingURL:
		console.log("Using staging config");
		config = firebaseConfigStaging;
		projectEnv = "staging"
		break;
	case devURL:
		console.log("Using dev config");
		config = firebaseConfigDev;
		projectEnv = "dev"
		break;
	case localURL:
		console.log("Localhost: using dev config");
		config = firebaseConfigDev;
		projectEnv = "local"
		break;
	default:
		console.log("No matching hostname, falling back to dev config");
		config = firebaseConfigDev;
		projectEnv = "dev"
}

const app: firebase.app.App = firebase.initializeApp(config);

export { projectEnv }

export default app;
