import { createStyles, Theme } from '@material-ui/core/styles';

const sharedStyles = (theme: Theme) => {
    return createStyles({
        dangerButton: {
            backgroundColor: theme.palette.error.main,
            color: "white",
            "&:hover": {
                backgroundColor: theme.palette.error.dark,
            }
        },
        tooltipHover: {
            cursor: "pointer",
            color: theme.palette.primary.main
        },
        imgFluid: {
            maxWidth: "100%",
            height: "auto"
        }
    })
}

export default sharedStyles;