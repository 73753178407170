import React, { useState, useEffect, useContext } from 'react';
import { useHistory, withRouter} from "react-router-dom";
import List from '@material-ui/core/List';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import db from "../../firebase/db"
import logAnalyticsEvent from "../../firebase/logAnalyticsEvent"
import firebase from "firebase";

import { MessageFolder } from '../../../shared/types';
import { Message } from "./types";


import { AuthContext } from '../../config/AuthProvider';

import MessageListItem from '../../components/MessageListItem';
import ViewMessage from "./ViewMessage";

import Toolbar from "./Toolbar";

import { HeightLayout, HeightLayoutChild, thinScrollbar, useScreenSize } from '@alethea-medical/alethea-components';

import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import IconButton from '@material-ui/core/IconButton';
import RefreshIcon from '@material-ui/icons/Refresh';
import { SearchBar, SearchOption } from '../../components/SearchBar';
import SnackbarMessage from '../../components/SnackbarMessage';
import { ProcessStatus, useProcessState } from "@alethea-medical/alethea-components"
import useUndoMoveFolder from './useUndoMoveFolder';
import { formatTimeLong } from '../../shared/formatTime';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        ...thinScrollbar,
        header: {
            padding: 0
        },
        hidden: {
			display: "none"
		},
        toolbar: {
            paddingLeft: theme.spacing(1)
        }
    }),
);

export interface MessageDict {
	[messageId: string]: Message
}

const searchOptions: SearchOption[] = [
    {field: "subType", display: "Message Type", options: [{value: "Forms", display: "Form Responses"}, {value: "OneWayPatient", display: "Doctor-Patient Messages"}, {value: "PatientCC", display: "Econsults"}, {value: "MOAMessage", display: "Doctor-MOA Messages"}]},
	{field: "subject", display: "Subject"},
]


const Messages = withRouter(({ location }) => {
    const classes = useStyles();


    const authContext = useContext(AuthContext);

    const loadMoreAmount = 20;
    const history = useHistory();
    
    const [messages, setMessages] = useState<MessageDict>({});
    const [newMessageQueue, setNewMessageQueue] = useState<Message[]>([]);
    const [openMessageId, setOpenMessageId] = useState<string | undefined>(undefined);
    const [selectedMessages, setSelectedMessages] = useState<string[]>([]);
    const [allSelected, setAllSelected] = useState<boolean>(false);
    const [oldestMessageTime, setOldestMessageTime] = useState<firebase.firestore.Timestamp>(firebase.firestore.Timestamp.now());
    const [folder, setFolder] = useState<MessageFolder>("inbox");

    const {processState, setProcessState, processErrorMessage, errorHandler } = useProcessState({ logAnalyticsEvent });

    const [enableSearch, setEnableSearch] = useState<boolean>(false);
    const [messageFilter, setMessageFilter] = useState<((message: Message) => boolean) | undefined>(undefined);

    const loadMessages = (fetchLessThan: firebase.firestore.Timestamp) => {
        db.collection("messages").where("userId", "==", authContext.uid).where("folder", "==", folder).where("created", "<", fetchLessThan).orderBy("created", "desc").limit(loadMoreAmount).get()
        .then(newMessagesHandler)
        .catch((error: Error) => {			
			errorHandler({
                error: error, 
                userMessage: "Error loading messages"
            });
		})
    }
        
	const loadMoreHandler = () => {
        logAnalyticsEvent("message_load_more");

		loadMessages(oldestMessageTime);
	}

    const newMessagesHandler = (snapshot: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>) => {
        if(snapshot.size > 0) {
			console.log(`Retrieved ${snapshot.size} messages`);
            const newMessages = snapshot.docs.filter((doc) => {
                return doc.exists;
            }).map((doc) => {
                return Object.assign(doc.data(), { id: doc.id }) as Message;
            })
            setNewMessageQueue(newMessages);
        }
	}

    const openMessageHandler = (id: string) => {
        logAnalyticsEvent("message_select");
        setOpenMessageId(id);
        markAsRead(id);
        history.push(location.pathname);//Push current path so when back is pressed, user stays on current page
    }

    const goBackHandler = () => {
        setOpenMessageId(undefined);
    }

    const tabChangeHandler = (_: any, value: MessageFolder) => {
        if(value !== folder) {
            setFolder(value);
            resetMessages();
        }
	}

    const runSearch = (filter: (message: Message) => boolean) => {
        logAnalyticsEvent("message_search");
        setEnableSearch(true);
        setMessageFilter(() => filter);
    }

    const clearSearch = () => {
        setEnableSearch(false);
        setMessageFilter(undefined);
    }

    const resetMessages = () => {
        setMessages({});
        setSelectedMessages([]);
        setOldestMessageTime(firebase.firestore.Timestamp.now());
    }
    
    const markAsRead = (messageId: string) => {
        if(!messages[messageId].read) {
            const newMessages = {...messages};
            db.collection("messages").doc(messageId).update({read: true})
            .then(() => {
                newMessages[messageId].read = true;
                setMessages(newMessages);            
            })
            .catch((error: Error) => {
                errorHandler({
                    error: error
                });
            })
        }
    }

    const messageSelectedHandler = (id: string, checked: boolean) => {
        if(checked) {
            if(!selectedMessages.includes(id)) {
                const newSelectedMessages = [...selectedMessages];
                newSelectedMessages.push(id);
                setSelectedMessages(newSelectedMessages);
            }
        }
        else {
            const idxToRemove = selectedMessages.indexOf(id);
            if(idxToRemove !== -1) {
                const newSelectedMessages = [...selectedMessages];
                newSelectedMessages.splice(idxToRemove, 1);
                setSelectedMessages(newSelectedMessages);
            }
        }
    }

    const selectAllHandler = (selectAll: boolean) => {
        if(selectAll) {
            const newSelectedMessages = [...selectedMessages];
            Object.keys(messages).forEach((id) => {
                if(!newSelectedMessages.includes(id)) {
                    newSelectedMessages.push(id);
                }
            })
            setSelectedMessages(newSelectedMessages);
        }
        else {
            setSelectedMessages([]);
        }
    }


    const handleRefresh = () => {
        resetMessages();
        loadMessages(firebase.firestore.Timestamp.now());
    }

    const onUndoMoveFolder = (prevFolder: MessageFolder, changedIds: string[]) => {
        return Promise.all(changedIds.map((id) => {
            return db.collection("messages").doc(id).update({folder: prevFolder})
        }))
        .then(() => {
            setShowUndoSnackbar(false);
        })
        .catch((error: Error) => {
            errorHandler({
                error: error, 
                userMessage: "Error undoing the previous action"
            });
        })
    }

    const { undo, setUndoIds, setPrevFolder } = useUndoMoveFolder(messages, setMessages, onUndoMoveFolder);
    const [ showUndoSnackbar, setShowUndoSnackbar ] = useState<boolean>(false);
    const [ undoSnackbarAction, setUndoSnackbarAction ] = useState<string>("");
    const [ showUnreadSnackbar, setShowUnreadSnackbar] = useState<boolean>(false);


    const markAsUnreadHandler = (messageIds: string[]) => {
        const newMessages = {...messages};
        return Promise.all(messageIds.map((id) => {
            return db.collection("messages").doc(id).update({read: false})
            .then(() => {
                newMessages[id].read = false;
            })
        }))
        .then(() => {
            logAnalyticsEvent("message_mark_unread");
            setShowUnreadSnackbar(true);
            setOpenMessageId(undefined);
            setMessages(newMessages);            
        })
        .catch((error: Error) => {
            errorHandler({
                error: error, 
                userMessage: "Error marking message as unread"
            });
        })
    }



    const moveFolderHandler = (messageIds: string[], newFolder: MessageFolder) => {
        const newMessages = {...messages};
        const newUndoIds: string[] = [];
        return Promise.all(messageIds.map((id) => {
            return db.collection("messages").doc(id).update({folder: newFolder})
            .then(() => {
                newUndoIds.push(id);
                delete newMessages[id];
            })
        }))
        .then(() => {
            logAnalyticsEvent(`message_move_to_${newFolder}`);

            setUndoSnackbarAction(newFolder)
            setUndoIds(newUndoIds);
            setPrevFolder(folder);
            setSelectedMessages([]);
            setShowUndoSnackbar(true);
            setOpenMessageId(undefined);
            setMessages(newMessages);            
        })
        .catch((error: Error) => {
            errorHandler({
                error: error, 
                userMessage: `Error moving messages to ${newFolder}`
            });
        })
    }


    useEffect(() => {
        loadMessages(firebase.firestore.Timestamp.now());
    }, [folder])

    useEffect(() => {
        return history.listen(() => {
            if(history.action === 'POP') {
                setOpenMessageId(undefined);//Go back to message list instead of previous route
            }
        })
    }, [history])

    	//Run when new activities are put into newActivityQueue
	useEffect(() => {
		//Put the update logic into a separate function from the onSnapshot function so that it contains
		//all updated values of activityIndex and activities
		const newMessages = {...messages};
		newMessageQueue.forEach((newMessage) => {
		    if(newMessage.created < oldestMessageTime) {
				setOldestMessageTime(newMessage.created);
			}
			newMessages[newMessage.id] = newMessage;
		})		
		setMessages(newMessages);
		
	}, [newMessageQueue])

    useEffect(() => {        
        if(selectedMessages.length > 0) {
            setAllSelected(Object.keys(messages).every((id) => {
                return selectedMessages.includes(id);
            }));
        }
        else {
            setAllSelected(false);
        }
    }, [selectedMessages])

    const { heightMinusAppBar } = useScreenSize({})

    return (
        <>
            <div className={openMessageId === undefined ? "" : classes.hidden}>
                <List className={classes.header}>
                    <HeightLayout height={heightMinusAppBar}>
                        <HeightLayoutChild flexDriver>
                            <ListItem divider>
                                {/* dynamically change orientation */}
                                <Tabs value={folder} onChange={tabChangeHandler}
                                    variant="fullWidth"
                                >
                                    <Tab value={"inbox"} label="Inbox"/>
                                    <Tab value={"archive"} label="Archive"/>
                                </Tabs>
                            </ListItem>
                            <ProcessStatus state={processState} setState={setProcessState} errorMessage={processErrorMessage} useSnackbar={true}/>
                            <ListItem divider>
                                <SearchBar enableSearch={enableSearch} searchOptions={searchOptions}
                                    runSearch={runSearch} clearSearch={clearSearch}
                                />
                            </ListItem>
                            <ListItem className={classes.toolbar} divider>
                                <Grid container spacing={1} justifyContent="flex-start" alignItems="center">
                                    <Grid item>
                                        <Checkbox checked={allSelected} onChange={(e) => { selectAllHandler(e.target.checked) }}/>
                                    </Grid>
                                    <Grid item>
                                        <IconButton onClick={handleRefresh}><RefreshIcon/></IconButton>
                                    </Grid>
                                    <Grid item>
                                        <Toolbar 
                                            folder={folder}
                                            atLeastOneSelected={selectedMessages.length > 0}
                                            markAsUnread={() => {markAsUnreadHandler(selectedMessages)}} 
                                            moveFolder={(folder: MessageFolder) => {moveFolderHandler(selectedMessages, folder)}} 
                                        />
                                    </Grid>
                                </Grid>
                            </ListItem>
                        </HeightLayoutChild>
                        <HeightLayoutChild flexDriven allowOverflowY className={classes.thinScrollbar}>
                            {Object.values(messages).sort((a, b) => {
                                if(a.created < b.created)
                                    return 1;
                                else if(a.created > b.created)
                                    return -1;
                                else
                                    return 0;
                            }).map((m, index) => {
                                const jsx = <MessageListItem 
                                                key={`message_${m.subject}_${index}`} 
                                                selected={selectedMessages.includes(m.id)}
                                                setSelected={messageSelectedHandler}
                                                openMessage={openMessageHandler}
                                                id={m.id}
                                                showAttachmentIcon={m?.messageMediaURIs?.length > 0}
                                                read={m.read}
                                                primary={m.subject}
                                                secondary={formatTimeLong(m.created.toDate())}
                                            />
                                if(messageFilter !== undefined) {
                                    if(messageFilter(m))
                                        return jsx;
                                    return <></>
                                }
                                else {
                                    return jsx
                                }
                            })}	
                            <ListItem
                                button
                                onClick={loadMoreHandler}
                                alignItems="center"
                                divider
                            >
                                <ListItemIcon>
                                    <ArrowDownwardIcon color="primary"/>
                                </ListItemIcon>
                                <ListItemText
                                    primary={enableSearch ? "Load More Search Results" : "Load More"}
                                />
                            </ListItem>
                        </HeightLayoutChild>
                    </HeightLayout>
                </List>
            </div>
            {openMessageId !== undefined && messages[openMessageId] !== undefined && (
                <ViewMessage 
                    message={messages[openMessageId]} 
                    goBackHandler={goBackHandler}
                    markAsUnread={() => {markAsUnreadHandler([openMessageId])}} 
                    moveFolder={(folder) => {moveFolderHandler([openMessageId], folder)}} 
                />
            )}
            <SnackbarMessage message={`Moved to ${undoSnackbarAction}`} show={showUndoSnackbar} setShow={setShowUndoSnackbar} useButton buttonText="UNDO" onClick={undo}/>
            <SnackbarMessage message={`Marked as unread`} show={showUnreadSnackbar} setShow={setShowUnreadSnackbar}/>
        </>
    );
})

export default Messages;