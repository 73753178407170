import { useState } from "react";
import { Controller } from "react-hook-form";
import { ControlledInputProps } from "../types";
import TextField from "@material-ui/core/TextField";
import { makeStyles } from "@material-ui/core/styles";
import Grid from '@material-ui/core/Grid';
import MenuItem from "@material-ui/core/MenuItem";
import ListItemText from '@material-ui/core/ListItemText';
import palette from "../../../palette"; // for sharedStyles (copied from entid-portal)

const sharedStyles = (theme: any) => {

    return {
      canDisable: {
        "& .MuiInputBase-root.Mui-disabled": {
            color: palette.disabledTextColor,
            backgroundColor: palette.disabledBGColor
        }
      }
    };
};

const useStyles = makeStyles((theme) => {
    return {
        ...sharedStyles(theme)
    }
});


interface FormSelectProps extends ControlledInputProps {
    options: any[],
    getOptionLabel?: (option: any) => string,
    getOptionLabelSecondary?: (option: any) => any,
    getOptionValue?: (option: any) => any,
    getOptionDisabled?: (option: any) => boolean,
    getEndAdornment?: (option: any) => React.ReactNode,
    disableWhenOne?: boolean,//Disable the field when only one option is available,
    defaultEmpty?: boolean,
    rules?: any,
    disabled?: boolean
}

const FormSelect = ({ name, control, options, getOptionLabel, getOptionLabelSecondary, getOptionValue, getOptionDisabled = () => false, getEndAdornment, disableWhenOne, defaultValue, label, defaultEmpty, rules, disabled, errorTextPadding, ...rest }: FormSelectProps) => {
    const classes = useStyles();

    const [isOpen, setIsOpen] = useState(false);

    const tryGetOptionValue = (option: any) => {
        if (option !== undefined)
            return getOptionValue ? getOptionValue(option) : option
        else
            return ''
    }

    const tryGetOptionLabel = (option: any) => {
        return getOptionLabel ? getOptionLabel(option) : option
    }

    const tryGetOptionLabelSecondary = (option: any) => {
        return getOptionLabelSecondary ? getOptionLabelSecondary(option) : null
    }

    const tryGetEndAdornment = (option: any) => {
        return getEndAdornment ? getEndAdornment(option) : null
    }

    /* Only allow selecting when the menu is open
    There is a bug where the menu will still be clickable after the user has selected an option, and the menu is closed
    This prevents the user from selecting an "invisible" item if they click fast enough
    */
    const handleSelectChange = (event: any, handler: (event: any) => void) => {
        if(isOpen) {
            handler(event)
        }
    }

    const handleSelectOpen = () => {
        setIsOpen(true);
    }

    const handleSelectClose = () => {
        setIsOpen(false);
    }

    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultEmpty ? undefined : defaultValue ? defaultValue : tryGetOptionValue(options[0])}
            rules={rules}
            render={({ field, fieldState }) =>
                <TextField
                    {...field}
                    {...rest}
                    className={classes.canDisable}
                    disabled={(disableWhenOne && options.length <= 1) || disabled}
                    select
                    label={label}
                    error={fieldState.error !== undefined}
                    helperText={errorTextPadding ? (fieldState.error?.message ? fieldState.error.message : " ") : fieldState.error?.message}
                    margin="dense"
                    fullWidth
                    variant="outlined"
                    SelectProps={{
                        onOpen: handleSelectOpen,
                        onClose: handleSelectClose,
                        onChange: (event) => handleSelectChange(event, field.onChange),
                        renderValue: (option) =>  {
                            const secondaryLabel = tryGetOptionLabelSecondary(option);
                            // Dropdown becomes too tall if ListItemText is used, even if secondary is blank
                            // To prevent this, render differently when not using a secondary label
                            if(secondaryLabel) {
                                return (
                                    <Grid container justifyContent="space-between" alignItems="center">
                                        <Grid item>
                                            <ListItemText 
                                                primary={tryGetOptionLabel(option)} 
                                                secondary={tryGetOptionLabelSecondary(option)}
                                                style={{whiteSpace: 'normal', wordWrap: "break-word"}}
                                            />
                                        </Grid>
                                        <Grid item style={{display: "flex", alignItems: "center"}}>
                                            {tryGetEndAdornment(option)}
                                        </Grid>
                                    </Grid>
                                )
                            }
                            else {
                                return (
                                    <Grid container justifyContent="space-between" alignItems="center">
                                        <Grid item style={{whiteSpace: 'normal', wordWrap: "break-word", margin: '0'}}>
                                            {tryGetOptionLabel(option)}
                                        </Grid>
                                        <Grid item style={{display: "flex", alignItems: "center"}}>
                                            {tryGetEndAdornment(option)}
                                        </Grid>
                                    </Grid>
                                )
                            }
                        }
                    }}
                >
                    {options.map((option: any, index: number) => {
                        return (
                        <MenuItem key={`${tryGetOptionLabel(option)}_${index}`} disabled={getOptionDisabled(option)} value={tryGetOptionValue(option)}>
                            <ListItemText 
                                primary={tryGetOptionLabel(option)} 
                                secondary={tryGetOptionLabelSecondary(option)}
                                style={{whiteSpace: 'normal', wordWrap: "break-word"}}
                            />
                            {tryGetEndAdornment(option)}
                        </MenuItem>
                        )
                    })}
                </TextField>
            }
        />
    );
}


export default FormSelect;