import React from "react";
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import AttachmentIcon from '@material-ui/icons/Attachment';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import Checkbox from '@material-ui/core/Checkbox';
import { InputLabel } from "@material-ui/core";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    thread: {       
        '&:hover': {
            borderColor: theme.palette.secondary.main,
            borderWidth: theme.spacing(0.5)
        },
        padding: 0,
        margin: 0
    },
    threadText: {   
        padding: theme.spacing(1),
        margin: 0,
        overflowX: "hidden",
        whiteSpace: "pre-line",
        wordWrap: 'break-word',//break lines on word to fit
    },
    unreadText: {
        color: theme.palette.text.primary,
        fontWeight: 500,
    },
    readText: {
        color: theme.palette.text.secondary,
    },
    readThread: {
        background: theme.palette.grey[200],
    },
    unreadThread: {
        //white
    },
    attachmentIcon: {
        marginRight: theme.spacing(2)
    },
    unreadIcon: {
        marginRight: theme.spacing(2)
    },
    selectedThread: {
        background: theme.palette.secondary.light
    },
    checkbox: {
        height: "100%",
        margin: theme.spacing(0),
        padding: theme.spacing(0, 1)
    },
  }),
);


interface MessageListItemProps {
    selected: boolean,
    setSelected: (id: string, checked: boolean) => void,
    openMessage: (id: string) => void,
    id: string,
    read?: boolean,
    showAttachmentIcon?: boolean,
    primary: React.ReactChild,
    secondary: React.ReactChild
}

const MessageListItem = ({ selected, setSelected, openMessage, id, read, showAttachmentIcon, primary, secondary}: MessageListItemProps) => {
    const classes = useStyles();


    const changeMessage = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        e.stopPropagation();
        openMessage(id);
    }

    return (<>
        <ListItem             
            className={`${classes.thread} ${selected ? classes.selectedThread : (read ? classes.readThread : classes.unreadThread)}`} 
            button 
            divider
        >
            <InputLabel className={classes.checkbox}>
                <Checkbox checked={selected} onChange={(e) => { e.stopPropagation(); setSelected(id, e.target.checked) }}/>
            </InputLabel>
            <ListItemText
                onClick={changeMessage}
                className={`${classes.threadText}`}
                primary={primary}
                primaryTypographyProps={{
                    className: `${read ? classes.readText : classes.unreadText}`
                }}
                secondary={secondary}
                secondaryTypographyProps={{
                    component:"span"
                }}
            />
            {showAttachmentIcon && (
                <AttachmentIcon className={classes.attachmentIcon} color="primary"/>
            )}
            {!read && (
                <FiberManualRecordIcon className={classes.unreadIcon} color="secondary"/>
            )}
        </ListItem></>
    );
}

export default MessageListItem;