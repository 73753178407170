import { FileType } from "../../types";

function getFileType (filename: string): FileType {
    if(/.*\.jpg|.*\.png|.*.jpeg/.test(filename)) {
        return "image"
    }
    else if(/.*\.mp4/.test(filename)) {
        return "video";
    }
    else {
        return "other";
    }
}

export default getFileType