import React, { useEffect, useState } from 'react';

import storage from "../../firebase/storage";
import Gallery from "../Gallery";
import getFileType from '../Gallery/getFileType';
import { FileProps } from "../../types";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        paper: {
            margin: theme.spacing(1)  
        },
        root: {
            padding: theme.spacing(1)
        },
        mediaHeader: {
            fontWeight: 500
        }
    }),
);


interface AttachmentViewerProps {
    messageMediaURIs: string[]
}

const AttachmentViewer = ({ messageMediaURIs }: AttachmentViewerProps) => {

    const classes = useStyles();

    const [messageMediaFiles, setmessageMediaFiles] = useState<FileProps[]>([]);

    useEffect(() => {
        Promise.all(messageMediaURIs.map((uri) => {
            return storage.ref(uri).getDownloadURL()
            .then((url: string) => {
                const uriSplit = uri.split('/');
                const filename = uriSplit[uriSplit.length - 1];
                return {
                    src: url,                    
                    filename: filename,
                    fileType: getFileType(filename),
                }
            })
            .catch((error) => {
                console.error(error);
                return undefined;
            })
        }))
        .then((files) => {
            setmessageMediaFiles(
                (files.filter((file) => file !== undefined) as FileProps[] )
                .sort((a, b) => {
                    return a.fileType === "image" || a.fileType === "video" ? 1 : -1
                }) 
            )
        })
    }, [messageMediaURIs])

    return (
        <>
            <Paper className={classes.paper}>
                <Grid container className={classes.root} spacing={1}>
                    <Grid item xs={12}>
                        <Typography component="h1" variant="h5" color="primary">Attachments</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.mediaHeader}>Files</Typography>
                        <ul>
                            {messageMediaFiles.map((file, index) => {
                                if(file.fileType === "other") {
                                    return (
                                        <li key={`attachment_${index}`}>
                                            <a href={file.src} target="_blank" rel="noopener noreferrer">
                                                {file.filename}
                                            </a>
                                        </li>
                                    )
                                }
                            })}
                        </ul>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography className={classes.mediaHeader}>Images and Videos</Typography>
                        <Gallery files={messageMediaFiles.filter((file) =>  file.fileType === "image" || file.fileType === "video")}/>
                    </Grid>

                </Grid>
            </Paper>
        </>
    );
}

export default AttachmentViewer;