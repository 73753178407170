export const firebaseConfigProd = {
    apiKey: "AIzaSyBwgY3GWjQKEyT51CPR7ik4M4HhHQAIsQk",
    authDomain: "alethea-patient-portal.firebaseapp.com",
    projectId: "alethea-patient-portal",
    storageBucket: "alethea-patient-portal.appspot.com",
    messagingSenderId: "19709453901",
    appId: "1:19709453901:web:6969a182b8d20730785e04",
    measurementId: "G-H3YEE9GFJ0"
};

export const firebaseConfigStaging = {
    apiKey: "AIzaSyB52R3zPvupEFmTTjKbRk-SK6ZWSxf9FW8",
    authDomain: "alethea-patient-portal-staging.firebaseapp.com",
    projectId: "alethea-patient-portal-staging",
    storageBucket: "alethea-patient-portal-staging.appspot.com",
    messagingSenderId: "103804301350",
    appId: "1:103804301350:web:d2028866f52df391da76e7",
    measurementId: "G-HLTGKTTPLJ"
};

export const firebaseConfigDev = {
    apiKey: "AIzaSyAibxicoFWoJOC2E462ZuguSLxdp6ShvCk",
    authDomain: "alethea-patient-portal-dev.firebaseapp.com",
    projectId: "alethea-patient-portal-dev",
    storageBucket: "alethea-patient-portal-dev.appspot.com",
    messagingSenderId: "69141694251",
    appId: "1:69141694251:web:282df1cf48164109c0a76e",
    measurementId: "G-GJE1RQP5WD"
};
