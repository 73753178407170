import React from 'react';
import { Content } from "@alethea-medical/react-components";
import { Route, Switch } from "react-router-dom";
import Sidebar from "../views/Sidebar";
import Messages from "../views/Messages";
import Profile from '../views/Profile';
import routes from './routes';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appBarOffset: {
            height: theme.spacing(6)
        }
    }),
);


const AuthRoutes = () => {
    const classes = useStyles();
    return (
        <>
            <Sidebar/>
            <Content>
                <div className={classes.appBarOffset}/>
                <Switch>
                    <Route path={routes.profile.path}>
                        <Profile/>
                    </Route>
                    <Route path={routes.messages.path}>
                        <Messages/>
                    </Route>
                </Switch>
            </Content>
        </>
    );
}

export default AuthRoutes;