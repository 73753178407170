import React, { useContext, useEffect }  from "react";
import clsx from 'clsx';
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import { AuthContext } from "../../config/AuthProvider";

import { Link, withRouter } from "react-router-dom";

import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Fade from '@material-ui/core/Fade';
import EmailIcon from '@material-ui/icons/Email';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import HelpIcon from '@material-ui/icons/Help';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useMobileSizes, useSidebar, Sidebar as AletheaSidebar, SidebarItem, AppBar, thinScrollbar } from "@alethea-medical/alethea-components";
import routes from "../../config/routes";
import sharedStyles from "../../sharedStyles";
import OneTimeTooltip from "../../components/OneTimeTooltip";
import logAnalyticsEvent from "../../firebase/logAnalyticsEvent";
import versionStrings from "../../versionStrings";
import { projectEnv } from "../../firebase/firebase";



const useStyles = makeStyles((theme: Theme) => ({
    ...sharedStyles(theme),
    ...thinScrollbar,
    header: {
        marginLeft: theme.spacing(2)
    },
    username: {
        whiteSpace: "normal",
    },
    logo: {
        marginRight: 0
    },
    versionString: {
        color: theme.palette.primary.main,
        fontSize: "0.75em"
    }
}))


const Sidebar = withRouter(({ location }) => {
    const classes = useStyles();
    const authContext = useContext(AuthContext);
    const isMobileSize = useMobileSizes();

    const { open, setOpen, showText, drawerWidthOpen, drawerWidthClosed, renderOpenCloseButtons, hideSidebarIfMobile } = useSidebar({ 
        isMobileSize, drawerWidthOpen: 230, drawerWidthClosed: 57
    });

    useEffect(() => {
        if(!isMobileSize){
            setOpen(true);
        }
        else {
            setOpen(false);
        }
    }, [isMobileSize])
    
    const showUserName = (): string => {
        const name = `${authContext?.profile?.firstName ? authContext.profile.firstName : ""} ${authContext?.profile?.lastName ? authContext.profile.lastName : ""}`
        if (!open)
            return "";
        if (showText)
            return name;
        return "";
    }

    return (
        <>
            <AppBar className="safeAreaTop safeAreaSides" open={open} setOpen={setOpen} header={Object.values(routes).find((r) => {
                return r.path === location.pathname
            })?.name} drawerWidthOpen={drawerWidthOpen} drawerWidthClosed={drawerWidthClosed} isMobileSize={isMobileSize}/>
            <AletheaSidebar paperClassName={clsx(classes.thinScrollbar, "safeAreaTop safeAreaSides safeAreaBottom")} open={open} setOpen={setOpen} isMobileSize={isMobileSize} drawerWidthOpen={drawerWidthOpen} drawerWidthClosed={drawerWidthClosed} >
                <List color="primary">
                    <ListItem >
                        <Grid container direction="row" alignItems="center">
                            <Grid item>
                                <img className={classes.imgFluid} src="/logo_big.png" alt="Alethea Logo" width="40" height="40"/>
                            </Grid>
                            {showText &&
                                <Grid item xs={1}>
                                    <Fade in={showText}>
                                        <Typography className={classes.header} variant="h6" color="primary">
                                            Alethea Care
                                        </Typography>
                                    </Fade>
                                </Grid>
                            }
                        </Grid>
                    </ListItem>
                    <Divider />
                    <SidebarItem route={routes.profile} label={showUserName()} textClass={classes.username} showText={showText} icon={<AccountCircleIcon color="primary" />} currentPath={location.pathname} onClick={hideSidebarIfMobile} linkComponent={Link}/>
                    <Divider />
                    <SidebarItem route={routes.messages} showText={showText} icon={<EmailIcon color="primary"/>} currentPath={location.pathname} onClick={hideSidebarIfMobile} linkComponent={Link}/>                            
                    <SidebarItem route={routes.auth_logout} showText={showText} icon={<ExitToAppIcon color="primary"/>} currentPath={location.pathname} onClick={hideSidebarIfMobile} linkComponent={Link}/>
                    <Divider />
                    <SidebarItem label="Support" tooltip="Support" showText={showText} icon={<HelpIcon color="primary" />} linkComponent="a" {...{href:"https://aletheamedical.com/support", target:"_blank", rel: "noreferrer noopener"}}/>
                    <OneTimeTooltip
                        header="New Feature!"
                        title={
                            <>
                                Fill your prescriptions online with nuPharma and skip the wait at the pharmacy.<br/>
                                Click "Online Pharmacy" to learn how you can have your prescriptions delivered to your door.
                            </>
                        }
                        tooltipName="nuPharma_sidebar_link"
                        placement="right"
                    >
                        {/* Analytics event is called nupharma_trial_link_2_open because the original nupharma_trial_link_open was for the incorrect URL (said partnership-alethea instead) */}
                        <SidebarItem label="Online Pharmacy" showText={showText} icon={<img className={classes.imgFluid} src="/nu_logo.png" width="25" alt="Nu Pharma Logo"/>} linkComponent="a" 
                            {...{href: "https://www.nupharma24.com/partnerships-alethea?hs_preview=EuvJmYKe-73362446849&utm_source=alethea.care&utm_medium=%20affiliates&utm_campaign=%20alethea_patient_trial_0722&utm_id=APC2z464h", 
                            target:"_blank", rel: "noreferrer noopener"}} onClick={() => {logAnalyticsEvent("nupharma_trial_link_2_open")}}/>
                    </OneTimeTooltip>
                    <Divider />
                    {renderOpenCloseButtons()}
                    {showText && 
                        <ListItem>
                            <Typography className={classes.versionString}>
                                Version {versionStrings.version}{projectEnv !== "prod" ? `, Build ${versionStrings.devBuild}` : ""}
                            </Typography>
                        </ListItem>
                    }
                    {(showText && projectEnv !== "prod") && (
                        <ListItem>
                            <Typography className={classes.versionString}>
                                Environment: {projectEnv.toUpperCase()}
                            </Typography>
                        </ListItem>
                    )}
                </List>
            </AletheaSidebar>
        </>
        );
})

export default Sidebar