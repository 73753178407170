import { aletheaOrangeLight, aletheaOrange } from "@alethea-medical/alethea-components";

const palette = {
    /** Basic Alethea dark green */
    darkGreen: "#05707d",
    darkGreenDark: "#214C54",
    /** Color MUI uses for button border variant=outlined, non-hover */
    darkGreenLight: "#80b5ba",
    /** Basic Alethea light green */
    lightGreen: "#86d0c8",
    lightGreenLight: "#e3f3ef",
    /** Basic Alethea orange */
    orange: aletheaOrange,
    orangeLight: aletheaOrangeLight,
    orangeLightLight: "#F36C241A",
    lightGray: "#DDD",
    disabledBGColor: "#E9ECEF",
    disabledTextColor: "#495057"
}

export default palette;