import React, { useEffect, useState } from "react";
import analytics from "../firebase/analytics";
import auth from "../firebase/auth";
import firebase from "firebase";
import { User } from "../../shared/types";
import getProfile from "../shared/getProfile";
import db from "../firebase/db";

interface ContextProps {
    user?: firebase.User,
    profile?: User,
    setProfile: (profile: User) => void,
    uid?: string,
    authenticated: boolean,
};

export const AuthContext = React.createContext<ContextProps>({
    setProfile: (profile: User) => { console.warn("setProifle not implemented") },
    authenticated: false
});

export const AuthProvider: React.FunctionComponent = ({children}) => {
    const [user, setUser] = useState<firebase.User>();
    const [profile, setProfile] = useState<User>();
    const [uid, setUid] = useState<string>();

    useEffect(() => {
        auth.onAuthStateChanged((newUser: firebase.User | null) => {
            console.log("Auth state changed:", newUser !== null ? "Signed in" : "Signed out")
            if(newUser !== null)
            {                
                getProfile(newUser.uid)
                .then((result: User) => {    
                    // fbAnalytics.setUserProperties({name: `${result?.firstName} ${result?.lastName}`});
                    setProfile(result);

                    // If signUpToken exists on the profile, remove it
                    if(result.signupToken) {
                        const userRef = db.collection("users").doc(newUser.uid);
                        userRef.update({
                            signupToken: firebase.firestore.FieldValue.delete()
                        })
                        .catch((error: Error) => {
                            console.error(error);
                        });
                    }
                })
                .catch((error: Error) => {
                    console.error(error);
                })    
                .finally(() => {
                    analytics.setUserId(newUser.uid);
                    setUser(newUser);
                    setUid(newUser.uid);
                })
            }
            else {
                setUser(undefined);
                setUid(undefined);
                setProfile(undefined);
            }
        });
    }, []);



    return (
        <AuthContext.Provider 
            value={{
                user: user,
                profile: profile,
                setProfile: setProfile,
                uid: uid,
                authenticated: user !== undefined,
            }}>
                {children}
        </AuthContext.Provider>
    );
}