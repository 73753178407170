import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../config/AuthProvider';
import { Control, FieldValues, UseFormTrigger, useWatch } from "react-hook-form";
import Grid from '@material-ui/core/Grid';
import { FormTextField, FormDate, PhoneInput, FormSelect, FormCheckbox } from "@alethea-medical/react-components";
import {  Link, Typography } from '@material-ui/core';
import PHNInput from '../PHNInput/PHNInput';
import storage from '../../firebase/storage';
import logAnalyticsEvent from '../../firebase/logAnalyticsEvent';

interface EditProfileProps {
    control: Control,
    isDisabled: boolean,
    trigger: UseFormTrigger<FieldValues>
    showEula?: boolean,
}

const EditProfile = ({ control, isDisabled, trigger, showEula }: EditProfileProps) => {
    const authContext = useContext(AuthContext);
    const provinces = ["AB", "BC", "MB", "NB", "NL", "NT", "NS", "NU", "ON", "PE", "QC", "SK", "YT"]
    const province = useWatch({control, name: "province", defaultValue: authContext?.profile?.province});
    const [eulaPdfLink, setEulaPdfLink] = useState()
    
    useEffect(() => {
        storage.ref("AgreementPdfs/EULA.pdf").getDownloadURL().then((url)=>{
            setEulaPdfLink(url)
        })
    },[])

    useEffect(()=> {
        trigger("phn")
    }, [province])
    
    const logEulaAnalytics = () => {
        logAnalyticsEvent("eula_opened");
    }

    return (
        <>
            <Grid item xs={12} sm={6}>
                <FormTextField
                    name="firstName"
                    control={control}
                    label="First Name"
                    rules={{required: {value: true, message: "First name is required"}}}
                    defaultValue={authContext?.profile?.firstName}
                    disabled={isDisabled}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <FormTextField
                    name="lastName"
                    control={control}
                    label="Last Name"
                    rules={{required: {value: true, message: "Last name is required"}}}
                    defaultValue={authContext?.profile?.lastName}
                    disabled={isDisabled}
                />
            </Grid>
            <Grid item xs={12}>
                <FormSelect
                    control={control}
                    name="province"
                    label="Province"
                    defaultValue={authContext?.profile?.province}
                    rules={{ required: { value: true, message: "Province is Required" } }}
                    disabled={isDisabled} 
                    options={provinces}               
                />
            </Grid>
            <Grid item xs={12}>
                <PHNInput
                    control={control}
                    name="phn"
                    label="Patient Health Number"
                    defaultValue={authContext?.profile?.phn}
                    rules={{required: {value: true, message: "PHN is required"}}}
                    disabled={isDisabled}
                    province={province}
                />
            </Grid>
            <Grid item xs={12}>
                <FormDate
                    control={control}
                    name="dateOfBirth"
                    label="Date of Birth"
                    defaultValue={authContext?.profile?.dateOfBirth?.toDate()}
                    disabled={isDisabled}
                    {...{openTo: "year"}}
                />
            </Grid>
            <Grid item xs={12}>
                <PhoneInput
                    control={control}
                    name="phone"
                    label="Phone Number"
                    defaultValue={authContext?.profile?.phone}
                    disabled={isDisabled}
                />
            </Grid>
             {showEula && <Grid item xs={ 12 }>
                <Grid container direction="row" alignItems="center" justifyContent="center" spacing={ 0 }>
                    <Grid item>
                        <FormCheckbox
                            label=''
                            name='eulaSigned'
                            defaultValue={ authContext?.profile?.eulaSigned }
                            control={ control }
                        />
                    </Grid>
                    <Grid item>
                        <Typography>I Agree to the <Link style={ { cursor: 'pointer' } }
                            onClick={logEulaAnalytics}
                            href={eulaPdfLink}
                            target="_blank"
                            rel="norefferer noopener">
                            End User Licence Agreement
                        </Link>
                        </Typography>
                    </Grid>
                </Grid>

            </Grid>}

        </>
    );
}

export default EditProfile;