import React from 'react';
import './App.css';
import CssBaseline from '@material-ui/core/CssBaseline';
import { baseTheme, Root } from "@alethea-medical/react-components";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import AuthRoutes from "./config/AuthRoutes";
import ServiceWorkerWrapper from './config/ServiceWorkerWrapper';

const theme = createTheme({
	...baseTheme,
	palette: {
		primary: {
		  main: "#05707d",
		},
		secondary: {
		  main: "#86d0c8",
		  light: "#e3f3ef"
		},
		text: {
		  
		}
	  },  
	typography: {
		...baseTheme.typography,
		h5: {
		//   color: "#999"	
		},
		subtitle1: {
			color: "#888",
			fontSize: "1.0em"
		}
	},
});

function App() {
  	return (
		<ThemeProvider theme={theme}>
			<ServiceWorkerWrapper/>
			<CssBaseline />
			<Root>
				<AuthRoutes/>
			</Root>
		</ThemeProvider>
	);
}

export default App;
